import crypto from 'crypto';

export const encryptRequestMiddleware = async (data) => {
  try {
    const secretKey = process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY; // Hardcoded for demonstration
    const iv = crypto.randomBytes(16); // Generate a random IV

    // Convert the secretKey string to a Buffer using hex encoding
    const secretKeyBuffer = Buffer.from(secretKey, 'hex');

    // Now pass the Buffer to createCipheriv
    const cipher = crypto.createCipheriv('aes-256-cbc', secretKeyBuffer, iv);

    let encrypted = cipher.update(JSON.stringify(data), 'utf8'); // Assuming data is a UTF-8 encoded string
    encrypted = Buffer.concat([encrypted, cipher.final()]);

    // Combine the IV and the encrypted data
    const encryptedDataStr = `${iv.toString('hex')}:${encrypted.toString('hex')}`;
    return encryptedDataStr;
  } catch (error) {
    console.error('Encryption error:', error);
    throw error; // Rethrow the caught error to allow further handling upstream
  }
};
